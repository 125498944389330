
import CapexAccounts from "~/graphql/Portfolio/CapexAccounts.gql";
import PropertyFinancial from "~/graphql/Portfolio/PropertyFinancial.gql";

import ViewCapexes from "~/pages/portfolio/components/Expenses/ViewCapexes.vue";

import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";

import { Operator } from "~/helpers/apollo/apollo-utility-helpers";

export default {
  components: { ViewCapexes },

  inheritAttrs: false,

  props: {
    propertyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    propertyFinancialRecords: {
      query: PropertyFinancial,
      variables() {
        return new InputBuilder()
          .setId(this.propertyId)
          .setPagination(this.currentPage + 1, this.pageSize)
          .setSort(this.sortBy, this.sortDirection)
          .setFilter({
            matches: [{ category_type: [Operator.In, ["capex", "CAPEX"]] }],
          })
          .build();
      },
      update(data) {
        return data.assetManagementProperty.assetManagementFinancialRecords;
      },
    },

    capexCategories: {
      query: CapexAccounts,
      variables() {
        return new InputBuilder()
          .setFilter(
            {
              matches: [{ category_type: [Operator.In, ["capex", "CAPEX"]] }],
            },
            "category"
          )
          .setFilter(
            {
              matches: [{ property_id: [Operator.Equals, this.propertyId] }],
            },
            "account"
          )
          .build();
      },
      update(data) {
        return data.assetManagementCategories.items;
      },
    },
  },

  data: () => ({
    currentPage: 0,
    pageSize: 20,
    sortBy: undefined,
    sortDirection: undefined,
  }),

  computed: {
    loading() {
      return this.$apollo.queries.propertyFinancialRecords.loading || this.$apollo.queries.capexCategories.loading;
    },

    financialRecords() {
      if (this.propertyFinancialRecords?.items == null) {
        return [];
      }

      return this.propertyFinancialRecords.items;
    },

    totalCount() {
      if (this.propertyFinancialRecords == null) {
        return 0;
      }

      return this.propertyFinancialRecords.metadata.totalCount;
    },

    accounts() {
      if (this.capexCategories == null) {
        return [];
      }

      return this.capexCategories.flatMap((category) => category.assetManagementAccounts.items);
    },
  },

  methods: {
    pageChange(page) {
      this.currentPage = page;
    },

    update() {
      this.$apollo.queries.propertyFinancialRecords.refetch();
    },

    sort({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection.toUpperCase();
    },
  },
};
